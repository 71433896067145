<template>
  <div class="protocol">
    <div v-html="agreementContent"></div>
  </div>
</template>

<script>
import { button } from '../api/getUserInfo';
export default {
  data() {
    return {
      cid: '',
      agreementContent: '',
    };
  },
  created() {
    this.cid = this.$route.query.cid;
    this.buttonTxt({ cid: this.cid });
  },
  methods: {
    // 获取按钮文案
    buttonTxt(data) {
      button(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.agreementContent = res.data.data.agreementContent;
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.protocol {
  padding: 24px 32px;
  color: #666;
}
/deep/.ql-align-center {
  text-align: center;
}
/deep/ h2 {
  text-align: center;
}
/deep/strong {
  background-color: #efefef !important;
}

/deep/span {
  background-color: #efefef !important;
  color: #666 !important;
}
h1 {
  text-align: center;
  padding: 30px 0px;
}
h2 {
  font-size: 30px;
}
/deep/ p {
  font-size: 26px;
  margin: 10px 0 20px 0;
  line-height: 40px;
  color: #666;
}
</style>
